import Slider from './Sliders'
import uniqid from 'uniqid'

export default class ProductDetails {
    constructor() {
        const THIS = this
        document.addEventListener("readystatechange", function() {
            switch (document.readyState) {
                case "complete":
                    THIS.resetSliders()
            }
        })
    }

    resetSliders() {
        const sliders = [...document.querySelectorAll(`.js-slider-product-details`)]
        sliders.forEach(slide => {
            slide.id = `slider-${uniqid()}`
            const slider = new Slider(slide.id, {
                slideBy : 'page',
                controls : false,
                autoplayButtonOutput : false,
            })
        })
    }
}
