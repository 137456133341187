import axios from 'axios'
import Cookies from 'js-cookie'
import Alert from './Alert'

export default class NewsletterForm {
    constructor() {
        this.form = document.querySelector(`.js-NewsletterForm`)
        this.url = `/mon-compte/newsletter/register/`
        this.formData = null

        if (this.form) this.init()
    }

    async onSubmit(e) {
        e.preventDefault()
        this.formData = new FormData(this.form)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))

        try {
            await axios.post(this.url, this.formData)
            new Alert(`Merci !`, `Vous êtes maintenant inscrit à notre newsletter.`)
        } catch(error) {
            new Alert(`Oups !`, `Il semble y avoir un problème avec votre adresse email.`)
        }
    }

    init() {
        this.form.addEventListener(`submit`, e => this.onSubmit(e))
    }
}
