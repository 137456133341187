export default class GoTo {
    constructor(select) {
        this.select = select
        this.url = select.getAttribute(`data-url`)
        this.argument = select.getAttribute(`data-argument`)
        this.site = window.location.origin
        this.init()
    }

    getPageUrl(page) {
        if (page === 1) {
            return `${this.site}${this.url}`
        } else {
            return `${this.site}${this.url}${this.argument}${page}`
        }
    }

    goToPage(page) {
        const url = this.getPageUrl(page)
        window.location.replace(url)
    }

    init() {
        this.select.addEventListener(`change`, () => {
            const page = parseInt(this.select.value)
            this.goToPage(page)
        })
    }
}
