export default class Blog {
    constructor() {
        this.imgs = [...document.querySelectorAll(`.js-Blog__Body img`)]
        this.init()
    }

    init() {
        this.imgs.forEach(img => {
            img.classList.add(`img-fluid`)
        })
    }
}
