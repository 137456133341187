import Modal from 'modal-vanilla/dist/modal'

class TModal {
    constructor(trigger) {
        this.trigger = trigger
        this.target = trigger.getAttribute('data-target')
        this.instance = null
        this.init()
    }

    init() {
        this.instance = new Modal({
            el: this.target,
        })
        window.modalInstances.push(this.instance)

        this.trigger.addEventListener('click', e => {
            e.preventDefault()
            this.instance.show()
        })
    }
}

export default class Modals {
    constructor() {
        this.triggers = document.querySelectorAll(`.js-Modal`)

        this.triggers.forEach(trigger => {
            new TModal(trigger)
        })
    }
}
