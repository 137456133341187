export default class Dropdown {
    constructor(wrapper, others) {
        this.others = others
        this.wrapper = wrapper
        this.button = wrapper.querySelector(`.dropdown-toggle`)
        this.menu = this.getMenu(wrapper)
        this.menuClasses = this.getClasses(this.menu)
        this.visible = `show`

        this.init()
    }

    getClasses(menu) {
        return menu.classList
    }

    getMenu(wrapper) {
        return wrapper.querySelector(`.dropdown-menu`)
    }

    isVisible() {
        return this.menuClasses.contains(this.visible)
    }

    show() {
        this.menuClasses.add(this.visible)
        this.hideOthers()
        setTimeout(() => {
            this.boundClickOutside = e => this.clickOutside(e)
            document.addEventListener('click', this.boundClickOutside)
        }, 100)
    }

    hide(classes = this.menuClasses) {
        classes.remove(this.visible)
        document.removeEventListener('click', this.boundClickOutside)
    }

    clickOutside(e) {
        const inside = this.menu.contains(e.target)
        if (!inside) this.hide()
    }

    hideOthers() {
        this.others.forEach(wrapper => this.hideOther)
    }

    hideOther(wrapper) {
        const menu = this.getMenu(wrapper)
        const classes = this.getClasses(menu)
        if (menu !== this.menu) this.hide(classes)
    }

    onClick() {
        this.isVisible() ? this.hide() : this.show()
    }

    init() {
        this.button.addEventListener(`click`, this.onClick.bind(this))
    }
}
