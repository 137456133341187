export default class Breakpoints {
    constructor() {
        this.registered = [
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
        ]

        this.value = null
        this.valueIndex = null
        this.size = null

        this.init()
    }

    setValue() {
        const body = document.querySelector(`body`)
        const style = window.getComputedStyle(body, `:after`)
        this.value = style.getPropertyValue(`content`).replace(/\"/g, ``)
        this.valueIndex = this.registered.indexOf(this.value)
        this.size = `${window.innerWidth}px | ${window.innerWidth/16}rem`
    }

    logBreakpoints() {
        if (!window.logBreakpoints) return null
        const style = `background-color: #17a2b8; color: white; padding: .3rem`
        const styleBold = `font-weight:bold;${style}`
        console.log(`%cBreakpoint %c${this.value} %c- Size %c${this.size}`, style, styleBold, style, styleBold)
    }

    onResize() {
        this.setValue()
        this.logBreakpoints()
    }

    init() {
        this.onResize()
        window.addEventListener(`resize`, this.onResize.bind(this))
    }
}
