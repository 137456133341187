import { getAttribute } from './GoogleTagManagerHelpers'

export default class Purchase {
    constructor(element) {
        this.element = element
        this.purchase = {
            id : this.formatAttribute(`id`),
            affiliation : this.formatAttribute(`affiliation`),
            revenue : this.formatAttribute(`revenue`),
            shipping : this.formatAttribute(`shipping`),
            tax : this.formatAttribute(`tax`),
            coupon : this.formatAttribute(`vouchers`),
        }

        return this.purchase
    }

    formatAttribute(name) {
        let attribute = getAttribute(this.element, name)
        if (
            attribute && name === 'revenue' ||
            attribute && name === 'shipping' ||
            attribute && name === 'tax'
        ) {
            attribute = attribute.replace(',', '.')
        }
        if (attribute && attribute === 'None') {
            attribute = null
        }
        return attribute
    }
}
