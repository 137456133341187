export default class ContactForm {
    constructor() {

        this.emailField = document.querySelector('#id_email')
        this.tokenField = document.querySelector('.js-RecaptchaToken')

        if (Boolean(this.tokenField)) {
            this.key = this.tokenField.getAttribute('data-key')

            this.init()
        }
    }

    init() {
        this.emailField.focus()
        grecaptcha.ready(async () => {
            const token = await grecaptcha.execute(this.key, {action: 'contact'})
            this.tokenField.value = token
        })
    }
}
