import Session from './Session'

export default class Header {

    constructor() {
        this.header = document.querySelector(`.js-Header`)
        this.headerHeightVar = `--header-height`
        this.setHeaderHeight()
        this.styles = document.documentElement.style

        this.reactiveElements = [
            {
                name : `cartItemsCount`,
                els : [...document.querySelectorAll(`.js-Header__CartCount`)],
            },
        ]
        this.init()
    }

    watchChanges() {
        this.reactiveElements.forEach(element => {
            document.addEventListener(`${element.name}.changed`, e => {
                const data = Session.get(element.name)
                element.els.forEach(el => {
                    if (element.name === 'cartItemsCount') {
                        if (data > 0) {
                            el.classList.remove(`d-none`)
                            el.classList.add(`d-inline-block`)
                        } else {
                            el.classList.add(`d-none`)
                            el.classList.remove(`d-inline-block`)
                        }
                    }
                    el.innerHTML = data
                })
            })
        })
    }

    setHeaderHeight() {
        this.headerHeight = this.header.offsetHeight
    }

    manageHeaderHeight() {
        this.setHeaderHeight()
        this.styles.setProperty(this.headerHeightVar, `${this.headerHeight}px`)
    }

    init() {
        this.manageHeaderHeight()
        window.addEventListener(`resize`, this.manageHeaderHeight.bind(this))
        this.watchChanges()
    }
}
