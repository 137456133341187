import Session from './Session'

export default class AdminLinks {
    constructor() {
        this.adminLink = null
        this.figuresLink = null
        this.pimLink = null
        this.wrapper = document.querySelector(`.js-Product__MetaLinks`)
        this.init()
    }

    renderIcon(name, brand=false) {
        const prefix = brand ? `fab` : `fa`
        return `<i class="${prefix} fa-${name} mr-1"></i>`
    }

    renderButton({title, link, icon, variant, brand=false}) {
        return `
            <a title="${title}" href="${link}" data-no-instant class="btn btn-${variant} btn-sm">
                ${this.renderIcon(icon, brand)} ${title}
            </a>
        `
    }

    renderLinks() {
        const html = `
            <div class="AdminLinks btn-group mb-3">
                ${this.renderButton({
                    title   : `Admin`,
                    link    : this.adminLink,
                    icon    : 'pen',
                    variant : 'success',
                })}
                ${this.renderButton({
                    title   : `Chiffres`,
                    link    : this.figuresLink,
                    icon    : 'euro-sign',
                    variant : 'danger',
                })}
                ${this.renderButton({
                    title   : `Pim`,
                    link    : this.pimLink,
                    icon    : 'product-hunt',
                    variant : 'primary',
                    brand   : true,
                })}
            </div>
        `
        const tmp = document.createElement(`div`)
        tmp.innerHTML = html
        this.wrapper.appendChild(tmp.querySelector(`.AdminLinks`))
    }

    init() {
        document.addEventListener(`pimLink.changed`, e => {
            this.adminLink = Session.get(`adminLink`)
            this.figuresLink = Session.get(`figuresLink`)
            this.pimLink = Session.get(`pimLink`)
            if (this.adminLink && this.wrapper) {
                this.renderLinks()
            }
        })
    }
}
