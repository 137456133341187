import { tns } from 'tiny-slider/src/tiny-slider'
import AddToCart from './AddToCart'

export default class Slider {

    constructor(id, options) {
        this.id = id
        this.selector = `#${id}`
        this.container = document.querySelector(this.selector)
        this.slider = null
        this.options = {
            ...options,
            ...this.dataOptions(),
        }
        return this.init()
    }

    dataOptions() {
        const jsonOptions = this.container.getAttribute(`data-slider`)
        const options = JSON.parse(jsonOptions)

        if (options.responsive) {
            const breakpoints = {}
            const responsive = Object.entries(options.responsive)
            responsive.forEach(([key, value]) => {
                breakpoints[parseInt(key)] = value
            })
            options.responsive = { ...breakpoints }
        }
        return options
    }

    onChange() {
        lazyLoad.update()
    }

    initAddToCartForms() {
        const forms = [
            ...this.container.querySelectorAll(`.add_to_cart--slider`)
        ]

        forms.forEach(form => {
            new AddToCart(form)
        })
        document.dispatchEvent(new Event('addtocart.create.all.end'))
    }

    init() {
        this.slider = tns({
            container : this.selector,
            ...this.options,
        })
        if (this.slider) {
            this.container.classList.remove(`invisible`)
            this.initAddToCartForms()
            this.slider.events.on(`indexChanged`, () => this.onChange())
            return this.slider
        }
    }
}

