import Session from './Session'

class Stock {
    constructor(ref, stock) {
        this.ref = ref
        this.stock = Math.floor(stock)
        this.elements = [...document.querySelectorAll(`.cgdpl-stock-info-${ref}`)]

        this.update()
    }

    update() {
        this.elements.forEach(el => el.innerText = `Qté dispo : ${this.stock}`)
    }
}

export default class Stocks {
    constructor() {
        this.init()
    }

    updateStock(ref, stock) {
        new Stock(ref, stock)
    }

    updateRefs() {
        Object.entries(Session.get(`stocks`)).forEach(([ref, stock]) => {
            this.updateStock(ref, stock)
        })
    }

    init() {
        document.addEventListener(`stocks.changed`, e => {
            this.updateRefs()
        })
    }
}
