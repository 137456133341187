import Session from './Session'

class Price {
    constructor(ref, price, priceIncVat) {
        this.ref = ref
        this.price = price
        this.priceIncVat = priceIncVat
        this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode
        this.elements = {
            price : [...document.querySelectorAll(`.price-space-${ref}`)],
            priceIncVat : [...document.querySelectorAll(`.price-space-vat-${ref}`)],
            mention : [...document.querySelectorAll(`.web-price-mention-${ref}`)],
            quantityMultiple : [...document.querySelectorAll(`.js-price-quantity-${ref}`)],
            batchPrices : this.getBatchPrices(),
            batchPricesTables : [...document.querySelectorAll(`.js-BatchPrices-${ref}`)],
        }
        this.quantityMultiple = 1
        this.updateBatchPrices()
        this.setQuantityMultiple()
        this.update()
    }

    getBatchPrices() {
        return [...document.querySelectorAll(`[data-batch-price-${this.ref}]`)]
    }

    formatFloat(float) {
        return new Intl.NumberFormat(
            'fr-FR',
            {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }
        ).format(float)
    }

    parseFloat(string) {
        return parseFloat(string.replace(',', '.'))
    }

    updateBatchPrices() {
        this.elements.batchPrices.forEach(element => {
            const batchPrice = this.parseFloat(element.getAttribute(
                `data-batch-price-${this.ref}`
            ))
            const price = this.parseFloat(this.price)
            if (batchPrice >= price) {
                element.remove()
            }
        })
        this.elements.batchPrices = this.getBatchPrices()
        if (this.elements.batchPrices.length < 1) {
            this.elements.batchPricesTables.forEach(element => {
                element.remove()
            })
        }
    }

    setQuantityMultiple() {
        [...this.elements.quantityMultiple].forEach(el => {
            this.quantityMultiple = parseInt(el.value)
        })
        if (this.quantityMultiple > 1) {
            let price = parseFloat(this.price.replace(',', '.'))
            let priceIncVat = parseFloat(this.priceIncVat.replace(',', '.'))

            price = price * this.quantityMultiple
            priceIncVat = priceIncVat * this.quantityMultiple
            this.price = this.formatFloat(price)
            this.priceIncVat = this.formatFloat(priceIncVat)

        }
    }

    changeElement(el, text) {
        el.innerHTML = text
        el.classList.remove(`fadeOut`)
        el.classList.add(`fadeIn`)
    }

    fadeOutIn(el, text) {
        el.addEventListener(`animationend`, e => {
            this.changeElement(el, text)
        })
        if (this.isIE11) {
            this.changeElement(el, text)
        }
        el.classList.add(`animated`, `fadeOut`)
    }

    update() {
        const { price, priceIncVat, mention } = this.elements
        price.forEach(el => this.fadeOutIn(el, this.price))
        priceIncVat.forEach(el => this.fadeOutIn(el, this.priceIncVat))
        mention.forEach(el => this.fadeOutIn(el, `Tarif personnalisé`))
    }
}

export default class Prices {
    constructor() {
        this.init()
    }

    updatePrice(ref, prices) {
        const [ price, priceIncVat ] = prices
        new Price(ref, price, priceIncVat)
    }

    updateRefs() {
        Object.entries(Session.get(`prices`)).forEach(([ref, prices]) => {
            this.updatePrice(ref, prices)
        })
    }

    init() {
        document.addEventListener(`prices.changed`, e => {
            this.updateRefs()
        })
    }
}
