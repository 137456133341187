import { getAttribute } from './GoogleTagManagerHelpers'
import Product from './GoogleTagManagerProduct'
import Purchase from './GoogleTagManagerPurchase'
import ClickEvent from './GoogleTagManagerClick'
import Cookies from 'js-cookie'

export default class GoogleTagManager {
    constructor() {
        this.productsElements = [...document.querySelectorAll(`[data-gtm-product]`)]
        this.purchaseElement = document.querySelector(`[data-gtm-purchase]`)
        this.clickElements = [...document.querySelectorAll(`[data-gtm-click-event]`)]
        this.purchase = null
        this.products = []

        this.cookieConsent = Cookies.get('cookie_consent')

        if (this.cookieConsent === '1') {
            this.init()
        }
    }

    mapEcProduct(product, list=false, quantity=false) {
        const result = {
            name: product.name,
            id: product.id,
            price: product.price,
            brand: product.brand,
            category: product.category,
        }
        if (list) {
            result.list = product.list
            result.position = product.position
        }
        if (quantity !== false) {
            result.quantity = parseInt(product.quantity)
        }
        return result
    }

    addImpressions() {
        const event = {
            ecommerce: {
                currencyCode: 'EUR',
                impressions: []
            }
        }
        this.products.forEach(product => {
            if (product.type === 'impression') {
                event.ecommerce.impressions.push(
                    this.mapEcProduct(product, true)
                )
            }
        })
        if (event.ecommerce.impressions.length) {
            window.dataLayer.push(event)
        }
    }

    addProductDetails() {
        const event = {
            ecommerce: {
                currencyCode: 'EUR',
                detail: {
                    products : []
                }
            }
        }
        this.products.forEach(product => {
            if (product.type === 'detail') {
                event.ecommerce.detail.products.push(
                    this.mapEcProduct(product)
                )
            }
        })
        if (event.ecommerce.detail.products.length) {
            window.dataLayer.push(event)
        }
    }

    addCheckoutResume() {
        const products = []
        const productIds = []
        let coupon = null
        this.products.forEach(product => {
            if (product.type === 'checkout' && parseInt(product.step) === 3) {
                coupon = product.coupon
                if (!productIds.includes(product.id)) {
                    products.push(
                        this.mapEcProduct(product, false, true)
                    )
                } else {
                    const found = products.find(p => p.id === product.id)
                    found.quantity += parseInt(product.quantity)
                }
                productIds.push(product.id)
            }
        })
        if (products.length) {
            this.checkout({step: 3, products, coupon})
        }
    }

    changeCart(event, method, product) {
        const result = {
            event,
            ecommerce: {
                currencyCode: 'EUR',
            },
        }
        result.ecommerce[method] = {
            products: [
                this.mapEcProduct(product, false, true)
            ],
        }
        return result
    }

    addToCart(product) {
        window.dataLayer.push(
            this.changeCart('addToCart', 'add', product)
        )
    }

    removeFromCart(product) {
        window.dataLayer.push(
            this.changeCart('removeFromCart', 'remove', product)
        )
    }

    addPurchase() {
        const event = {
            ecommerce: {
                purchase: {
                    actionField: {
                        ...this.purchase,
                    },
                    products: [],
                },
            }
        }
        this.products.forEach(product => {
            if (product.type === 'purchase') {
                event.ecommerce.purchase.products.push(
                    this.mapEcProduct(product, false, true)
                )
            }
        })
        if (event.ecommerce.purchase.products.length) {
            window.dataLayer.push(event)
        }

    }

    detailsClick(product) {
        const mappedProduct = this.mapEcProduct(product, true, false)
        delete mappedProduct.list
        const event = {
            event : 'productClick',
            ecommerce: {
                currencyCode: 'EUR',
                click: {
                    actionField : {
                        list : product.list,
                    },
                    products : [
                        mappedProduct
                    ],
                },
            },
        }
        if (event.ecommerce.click.products.length) {
            window.dataLayer.push(event)
        }
    }

    checkout({step, products, coupon}) {
        const event = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField : {
                        step,
                        coupon,
                    },
                    products: [],
                },
            },
        }
        products.forEach(product => {
            event.ecommerce.checkout.products.push(
                this.mapEcProduct(product, false, true)
            )
        })
        dataLayer.push(event)
    }

    init() {
        this.clickElements.forEach(element => {
            new ClickEvent(element)
        })

        this.productsElements.forEach(element => {
            const product = new Product(element)
            this.products.push(product)
        })

        if (this.purchaseElement) {
            this.purchase = new Purchase(this.purchaseElement)
        }

        this.addImpressions()
        this.addProductDetails()
        this.addCheckoutResume()
        this.addPurchase()

        document.body.addEventListener(`GTM:AddToCart`, e => {
            this.addToCart(e.detail?.product)
        })

        document.body.addEventListener(`GTM:DetailsClick`, e => {
            this.detailsClick(e.detail?.product)
        })

        document.body.addEventListener(`GTM:RemoveFromCart`, e => {
            this.removeFromCart(e.detail?.product)
        })

        document.body.addEventListener(`GTM:ChangeQuantity`, e => {
            if (e.detail?.product.quantity > 0) {
                this.addToCart(e.detail?.product)
            }
            if (e.detail?.product.quantity < 1) {
                let quantity = e.detail.product.quantity
                quantity = quantity - (quantity * 2)
                e.detail.product.quantity = quantity
                this.removeFromCart(e.detail?.product)
            }
        })

        document.body.addEventListener(`GTM:Checkout`, e => {
            this.checkout(e.detail)
        })
    }
}
