import axios from 'axios'
import Cookies from 'js-cookie'

export default class LoadAddress {
    constructor() {
        this.select = document.querySelector(`.js-LoadAddress`)
        this.url = `/mon-panier/complete-adresse/`
        this.formData = null
        this.address = {}

        if (this.select) this.init()
    }

    fillForm() {
        Object.entries(this.address).forEach(([key, value]) => {
            let name = key.replace(`phone_1`, `phone`)
            name = key.replace(`id`, `contact_id`)
            const selector = `input[name='shipping_${name}']`
            const input = document.querySelector(selector)
            if (input) input.value = value
        })

        const city = document.querySelector(`select[name='shipping_city'] optgroup`)
        city.innerHTML = `<option value="${this.address.city}">${this.address.city}</option>`
    }

    async onAddressChange() {
        this.formData = new FormData()
        this.formData.append(`address_id`, this.select.value)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))

        try {
            const { data } = await axios.post(this.url, this.formData)
            this.address = data
            this.fillForm()
        } catch(error) {
            console.error(`Oups !`, `Il semble y avoir un problème.`)
        }
    }

    init() {
        this.select.addEventListener(`change`, () => this.onAddressChange())
    }
}
