import Modal from 'modal-vanilla/dist/modal'

export default class UnavailableProductModal {
    constructor() {
        this.instance = null
        this.init()
    }

    init() {
        window.addEventListener('load', e => {
            const unavailable = window.location.search.includes('produit=indisponible')
            if (unavailable) {
                this.instance = new Modal({
                    el: document.querySelector('#modal-unavailable-product')
                })
                window.modalInstances.push(this.instance)
                this.instance.show()
            }
        })
    }
}
