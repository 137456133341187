export default class Filters {
    constructor() {
        this.buttonShow = document.querySelector('.js-filters-button-show')
        this.buttonHide = document.querySelector('.js-filters-button-hide')
        this.section = document.querySelector('.js-filters-section')
        this.inputsRanges = document.getElementsByClassName('js-filters-form-control-range')
        this.init()
    }

    init() {
        if (this.buttonShow != null) {
            this.buttonShow.addEventListener("click", () => {
                this.buttonShow.classList.add("d-none")
                this.buttonHide.classList.remove("d-none")
                this.section.classList.remove("d-none")
            })
        }
        if (this.buttonHide != null) {
            this.buttonHide.addEventListener("click", () => {
                this.buttonHide.classList.add("d-none")
                this.buttonShow.classList.remove("d-none")
                this.section.classList.add("d-none")
            })
        }
        if (this.inputsRanges != null) {
            for (let i = 0; i < this.inputsRanges.length; i++) {
                let inputRange = this.inputsRanges[i]
                let input = inputRange.querySelector("input")
                input.addEventListener("change", (val) => {
                    let span = inputRange.querySelector('span')
                    span.innerHTML = input.value
                })
            }
        }
    }
}
