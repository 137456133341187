class ToggleMenu {
    constructor(button) {
        this.button = button
        this.menu = document.querySelector(button.getAttribute(`data-target`))
        this.icon = button.querySelector(`.fal`)

        this.classes = this.menu.classList
        this.iconClasses = this.icon.classList

        this.visible = button.getAttribute(`data-visible`) || `d-flex`
        this.hidden = button.getAttribute(`data-hidden`) || `d-none`

        this.bars = `fa-bars`
        this.cross = `fa-times`

        this.init()
    }

    show(show = true) {
        this.classes.remove(show ? this.hidden : this.visible)
        this.classes.add(show ? this.visible : this.hidden)
        this.iconClasses.remove(show ? this.bars : this.cross)
        this.iconClasses.add(show ? this.cross : this.bars)
    }

    hide() {
        this.show(false)
    }

    toggle() {
        this.isVisible() ? this.hide() : this.show()
    }

    isVisible() {
        return this.classes.contains(this.visible)
    }

    init() {
        this.button.addEventListener(`click`, this.toggle.bind(this))
    }
}



export default class MainMenu {
    constructor(menu) {
        this.menu = menu
        this.buttons = [...document.querySelectorAll(`.js-MainMenu__Toggle`)]

        this.classes = this.menu.classList

        this.visible = `d-flex`
        this.hidden = `d-none`

        this.init()
    }


    show(show = true) {
        this.classes.remove(show ? this.hidden : this.visible)
        this.classes.add(show ? this.visible : this.hidden)
    }

    hide() {
        this.show(false)
    }

    onResize() {
        window.innerWidth > 991 ? this.show() : this.hide()
    }

    init() {
        this.onResize()
        window.addEventListener(`resize`, this.onResize.bind(this))
        this.buttons.forEach(button => new ToggleMenu(button))
    }
}
