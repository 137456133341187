import Cookies from 'js-cookie'


export default class CookiesConsentAag {
    constructor() {
        this.scripts = document.querySelectorAll('.js-CookiesConsent__Script')
        this.cookieName = `cookie_consent`
    }
    consent(items=1) {
        Cookies.set(this.cookieName, items, {
            expires : 365 * 10,
            path : `/`,
        })
        if (items === 1) {
            this.setScripts()
        }
    }
    setScripts() {
        this.scripts.forEach(script => {
            script.setAttribute('type', 'application/javascript')
            new Function(script.innerText)()
        })
    }
}
