const updateNotification = () => {
    window.addEventListener('issueMessage:read', (event) => {
        const unreadNotification = document.querySelector(`#js-NotificationCount`)
        unreadNotification.innerHTML = String(event.detail.count)
        if (event.detail.count <= 0) {
            unreadNotification.classList.add('d-none')
        }
    })
}

export default updateNotification
