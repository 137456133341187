export default class ToggleElement {
    constructor(trigger) {
        this.trigger = trigger
        this.targetSelector = trigger.getAttribute(`data-target`)
        this.targets = [...document.querySelectorAll(this.targetSelector)]
        this.visible = trigger.getAttribute(`data-visible`) ? trigger.getAttribute(`data-visible`) : `d-block`
        this.moreText = trigger.getAttribute(`data-more-text`) ? trigger.getAttribute(`data-more-text`) : false
        this.lessText = trigger.getAttribute(`data-less-text`) ? trigger.getAttribute(`data-less-text`) : false
        this.hidden = trigger.getAttribute(`data-hidden`) ? trigger.getAttribute(`data-hidden`) : `d-none`

        this.event = this.getEvent()

        this.init()
    }

    getEvent() {
        switch (this.trigger.tagName) {
            case 'A':
                return 'click'
                break
            case 'INPUT':
                return 'change'
                break
            default:
                return 'click'
        }
    }


    toggle(target) {
        const classes = target.classList
        const isVisible = classes.contains(this.visible)
        if (isVisible) {
            classes.add(this.hidden)
            classes.remove(this.visible)
            if (this.moreText && this.lessText) {
                this.trigger.innerHTML = 'Voir plus'
            }
        } else {
            classes.remove(this.hidden)
            classes.add(this.visible)
            if (this.moreText && this.lessText) {
                this.trigger.innerHTML = 'Voir moins'
            }
        }
    }

    toggleTargets() {
        this.targets.forEach(target => this.toggle(target))
    }

    init() {
        this.trigger.addEventListener(this.event, this.toggleTargets.bind(this))
    }
}
