import axios from 'axios'
import Cookies from 'js-cookie'

export default class CitySelector {
    constructor(input) {
        this.zipInput = input
        this.citySelect = this.getCitySelect()
        this.optionGroup = this.citySelect.querySelector(`optgroup`)
        this.errorHelp = this.citySelect.nextElementSibling
        this.url = `/mon-panier/complete-ville/`
        this.init()
    }

    getCitySelect() {
        const className = this.zipInput.getAttribute(`data-cities`)
        return document.querySelector(`.${className}`)
    }

    isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value)
    }

    renderValue(value) {
        return `<option value="${value}">${value}</option>`
    }

    resetErrors() {
        this.citySelect.classList.remove(`is-invalid`)
        this.errorHelp.innerText = ``
    }

    error() {
        this.citySelect.classList.add(`is-invalid`)
        this.errorHelp.innerText = `Aucune ville ne correspond à ce code postal`
    }

    populateSelect(cities) {
        let html = ``
        if (!cities.length) this.error()

        cities.forEach(city => {
            html += this.renderValue(city, city)
        })
        this.citySelect.value = cities[0]
        this.optionGroup.innerHTML = html
    }

    async onZipChange() {
        this.resetErrors()
        this.formData = new FormData()
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))
        this.formData.append(`zip_code`, this.zipInput.value)

        const { data : { cities } } = await axios.post(this.url, this.formData)
        this.populateSelect(cities)
    }

    init() {
        this.zipInput.addEventListener(`change`, e => {
            this.onZipChange()
        })
    }
}
