import axios from 'axios'
import Cookies from 'js-cookie'
import Session from './Session'
import Alert from './Alert'
import Modal from 'modal-vanilla/dist/modal'

export default class AddToCart {
    constructor(form) {
        this.form = form
        this.url = this.form.getAttribute(`action`)
        this.modals = [
            '#modal-dispo-agence',
            '#modal-dispo',
        ]
        this.formData = null

        this.init()
    }

    closeModals() {
        window.modalInstances.forEach(modal => {
            if (modal._visible) {
                modal.hide()
            }
        })
    }

    async submit(e) {
        e.preventDefault()

        this.formData = new FormData(this.form)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))

        try {
            const response = await axios.post(this.url, this.formData)
            this.closeModals()
            setTimeout(() => {
                new Alert(`Alerte ajoutée`, `Merci. Vous recevrez un email dès que ce produit sera disponible`)
            }, 200)
        } catch(e) {
            console.error(e)
        }
    }

    init() {
        this.form.addEventListener(`submit`, e => this.submit(e))
    }
}
