import axios from 'axios'
import Cookies from 'js-cookie'
import Alert from './Alert'

export default class OrderForm {
    constructor() {
        this.form = document.querySelector(`.js-OrderForm`)
        this.eventUrl = `${window.location.origin}/mon-panier/systempay-event/`
        this.paymentType = 'credit_card'
        this.actions = {
            credit_card : `https://paiement.systempay.fr/vads-payment/`,
            invoice : `${window.location.origin}/mon-panier/paiement-compte/`,
        }
        this.action = this.actions[this.paymentType]
        this.formData = null

        if (this.form) {
            this.cgv = this.form.querySelector(`.js-OrderForm__CGV`)

            this.init()
        }
    }

    noCGV() {
        new Alert(`Conditions Générales de Vente`, `Veuillez s'il vous plait accepter les conditions générales de vente avant de poursuivre.`)
    }

    async systempayEvent() {
        this.formData = new FormData(this.form)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))
        const response = await axios.post(this.eventUrl, this.formData)
    }

    onSubmit(e) {
        e.preventDefault()
        var THIS = this
        axios.post(`${window.location.origin}/mon-panier/submit-cart-final/`, null)
        .then(function (response) {
            THIS.doSubmit(e)
        })
        .catch(function (error) {
            location.href = `${window.location.origin}/mon-panier/`
        });
    }
    
    doSubmit(e) {
        this.paymentType = this.form.querySelector(`input[name="payment_type"]:checked`).value
        this.action = this.actions[this.paymentType]
        this.form.setAttribute('action', this.action)
        if (!this.cgv.checked)
            this.noCGV()
        if (this.paymentType === 'credit_card')
            this.systempayEvent()
        
        if (this.cgv.checked)
            this.form.submit()
    }

    init() {
        this.form.addEventListener(`submit`, e => this.onSubmit(e))
    }
}
