import axios from 'axios'

class Matomo {
    constructor(tag) {
        this.tag = tag
        this.productRefTag = this.tag.querySelector(`.product-ref`)
        this.productRefToddTag = this.tag.querySelector(`span.product-ref-todd`)
        this.productActualTitleTag = this.tag.querySelector(`span.product-actual-title`)
        this.productMainCategoryStringRepresentationTag = this.tag.querySelector(`span.product-main-category-string-representation`)
        this.productRef = null
        if (this.productRefTag != null)
            this.productRef = this.productRefTag.value
        this.productRefTodd = this.productRefToddTag.innerText
        this.productActualTitle = this.productActualTitleTag.innerText
        this.productMainCategoryStringRepresentation = this.productMainCategoryStringRepresentationTag.innerText
    }
    
    addToCart(quantity) {
        const THIS = this
        
        let pricesSpacesTags = document.getElementsByClassName("price-space-" + this.productRef)
        let priceSpaceTag = null
        if (pricesSpacesTags.length > 0) {
            priceSpaceTag = pricesSpacesTags[0]
        }
        if (priceSpaceTag != null) {
            let price = priceSpaceTag.innerText
        
            axios.get(`${window.location.origin}/session-cart/`)
            .then(function (responseSessionCart) {
                let cartTotalExVat = null
                if (responseSessionCart.data) {
                    cartTotalExVat = responseSessionCart.data.total_ex_vat
                }
                if (cartTotalExVat != null) {
                    let productsCategories = [THIS.productMainCategoryStringRepresentation]
                    
                    _paq.push(['addEcommerceItem',
                        THIS.productRefTodd,
                        THIS.productActualTitle,
                        productsCategories,
                        parseFloat(price.replace(",", ".")),
                        quantity
                    ])
                    _paq.push(['trackEcommerceCartUpdate', cartTotalExVat])
                }
            })
            .catch(function (error) {
                console.error("axios error in Matomos.addToCart() :")
                console.dir(error)
            });
        }
    }

    removeFromCart() {
        Matomo.removeFromCart(this.productRefTodd)
    }
    
    static removeFromCart(productRefTodd) {
        axios.get(`${window.location.origin}/session-cart/`)
        .then(function (responseSessionCart) {
            let cartTotalExVat = null
            if (responseSessionCart.data) {
                cartTotalExVat = responseSessionCart.data.total_ex_vat
            }
            if (cartTotalExVat != null) {
                _paq.push(['removeEcommerceItem', productRefTodd])
                _paq.push(['trackEcommerceCartUpdate', cartTotalExVat])
            }
        })
        .catch(function (error) {
            console.error("axios error in Matomos.removeFromCart() :")
            console.dir(error)
        });
    }
          
    viewProduct() {
        let productsCategories = [this.productMainCategoryStringRepresentation]
        
        let pricesSpacesTags = document.getElementsByClassName("price-space-" + this.productRef)
        let priceSpaceTag = null
        if (pricesSpacesTags.length > 0) {
            priceSpaceTag = pricesSpacesTags[0]
        }
        let price = null
        if (priceSpaceTag != null) {
            price = priceSpaceTag.innerText
        }
        let paq_values = ['setEcommerceView',
            this.productRefTodd,
            this.productActualTitle,
            productsCategories
        ]
        if (price != null) {
            paq_values.push(parseFloat(price.replace(",", ".")))
        }
        _paq.push(paq_values)
        _paq.push(['trackPageView'])
    }
}

export default class Matomos {
    constructor() {
        this.matomos = []
    }
    
    createMatomo(tag) {
        let matomo = new Matomo(tag)
        this.removeMatomo(matomo.productRefTodd)
        this.matomos.push(matomo)
        return matomo
    }
    
    findMatomo(productRefTodd) {
        let matomoFound = null
        for (let i=0;i<this.matomos.length;i++) {
            let matomo = this.matomos[i]
            if (matomo.productRefTodd == productRefTodd) {
                matomoFound = matomo
                break
            }
        }
        return matomoFound
    }
    
    removeMatomo(productRefTodd) {
        let matomoIndexFound = null
        for (let i=0; i < this.matomos.length; i++) {
            let matomo = this.matomos[i]
            if (matomo.productRefTodd == productRefTodd) {
                matomoIndexFound = i
                break
            }
        }
        if (matomoIndexFound != null) {
            this.matomos.splice(matomoIndexFound, 1)
        }
    }
    
    removeProductFromCart(productRefTodd) {
        Matomo.removeFromCart(productRefTodd)
    }
    
    trackEcommerceOrder(
        lines,
        cartIdPrologueFormat,
        grandTotal,
        subTotal,
        vat,
        shippingFees,
        hasDiscountVoucher
    ) {
        for (let i=0; i<lines.length; i++) {
            let line = lines[i]
            if (line.price > 0) {
                _paq.push(['addEcommerceItem',
                    line.refTodd,
                    line.actualTitle,
                    line.categories,
                    line.price,
                    line.quantity
                ])
            }
        }
        
        _paq.push(['trackEcommerceOrder',
            cartIdPrologueFormat, // (Required) orderId
            grandTotal, // (Required) grandTotal (revenue)
            subTotal, // (Optional) subTotal
            vat, // (optional) tax
            shippingFees, // (optional) shipping
            hasDiscountVoucher // (optional) discount
        ]);
    }
    
    async tryCreateLocalStats(cartIdPrologueFormat) {
        let isMatomoExist = null
        await axios.post(
            `${window.location.origin}/mon-panier/confirmation-done/`, 
            {
                // data to sent to the server - post body
                // it can be an empty object
                "cartIdPrologueFormat": cartIdPrologueFormat,
            },
            {
                // specify query parameters
            }
        ).then(function (response) {
            isMatomoExist = response.data.is_matomo_exist
        });
        return isMatomoExist
    }
}
