export default class SearchForm {
    constructor() {
        this.form = document.querySelector(`.js-SearchForm`)
        this.icon = document.querySelector(`.js-SearchForm__Icon`)
        this.input = document.querySelector(`.js-SearchForm__Input`)
        this.search = `fa-search`
        this.spin = `fa-spin`
        this.spinner = `fa-spinner`
        this.shadow = `shadow-sm`
        this.origin = window.location.origin

        if (this.form) this.init()
    }

    onSubmit(e) {
        e.preventDefault()
        const query = this.input.value
        if (query) {
            this.icon.classList.remove(this.search)
            this.icon.classList.add(this.spinner, this.spin)
            window.location.assign(`${this.origin}/recherche/${query}`)
        }
    }

    onFocusIn() {
        this.form.classList.add(this.shadow)
    }

    onFocusOut() {
        this.form.classList.remove(this.shadow)
    }

    init() {
        this.form.addEventListener(`submit`, this.onSubmit.bind(this))
        this.input.addEventListener(`focusin`, this.onFocusIn.bind(this))
        this.input.addEventListener(`focusout`, this.onFocusOut.bind(this))
    }
}
