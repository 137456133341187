import { getAttribute } from './GoogleTagManagerHelpers'

window.GTMClickEventElements = []

export default class ClickEvent {
    constructor(element, {category=null, action=null, label=null, value=null, listen='click'}={}) {
        this.element = element
        this.category = category || this.getAttribute(`category`)
        this.action = action || this.getAttribute(`action`)
        this.label = label || this.getAttribute(`label`)
        this.value = value || this.getAttribute(`value`)
        this.link = this.element.getAttribute('href')
        this.callback = !this.getAttribute('nocallback')
        this.listen = listen
        if (!GTMClickEventElements.includes(this.element)) {
            this.init()
        }
        GTMClickEventElements.push(this.element)
    }

    getAttribute(name) {
        return getAttribute(this.element, name)
    }

    submitEvent() {
        const event = {
            event : 'click',
            clickCategory : this.category,
            clickAction : this.action,
            clickLabel : this.label,
        }
        if (this.value) {
            event.clickValue = this.value
        }
        if (this.callback) {
            event.eventCallback = () => {
                document.location = this.link
            }
        }
        window.dataLayer.push(event)
    }

    onClick(e) {
        e.preventDefault()
        this.submitEvent()
    }

    init() {
        if (this.listen === 'click') {
            this.element.addEventListener(`click`, e => this.onClick(e))
        }
    }
}

export { ClickEvent }
