import Modal from 'modal-vanilla/dist/modal'

export default class Alert {
    constructor(title, text) {
        this.title = title
        this.text = text
        this.element = document.querySelector(`#modal-alert`)
        const modal = new Modal({
            el: this.element,
        })
        window.modalInstances.push(modal)
        this.instance = modal
        this.render()
        this.instance.show()
    }

    render() {
        const title = this.element.querySelector(`.modal-title`)
        const text = this.element.querySelector(`.modal-body`)
        title.innerText = this.title
        text.innerText = this.text
    }
}
