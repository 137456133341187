import { getAttribute } from './GoogleTagManagerHelpers'
import ClickEvent from './GoogleTagManagerClick'

export default class Product {
    constructor(element) {
        this.element = element
        this.checkout = this.formatAttribute(`checkout`)

        this.product = {
            id : this.formatAttribute(`id`),
            name : this.formatAttribute(`name`),
            category : this.formatAttribute(`category`),
            brand : this.formatAttribute(`brand`),
            price : this.formatAttribute(`price`),
            type : this.formatAttribute(`type`),
            list : this.formatAttribute(`list`),
            position : this.formatAttribute(`position`),
            step : this.formatAttribute(`step`),
            quantity : this.formatAttribute(`quantity`),
            coupon : this.formatAttribute(`vouchers`),
        }

        this.addToCart = this.element.querySelector(`[data-gtm-product-add]`)

        this.detailsLinks = [...this.element.querySelectorAll(`[data-gtm-product-details]`)]

        this.init()

        return this.product
    }

    onAddToCart() {
        const quantityInput = this.addToCart.querySelector('[name="quantity"]')
        const clickEvent = new ClickEvent(this.addToCart, {
            category: 'UX',
            action: 'click',
            label: 'Add to cart',
            listen: 'submit',
        })
        clickEvent.callback = false
        this.addToCart.addEventListener(`submit`, e => {
            clickEvent.submitEvent()
            const quantity = parseInt(quantityInput?.value)
            const event = new CustomEvent(
                'GTM:AddToCart',
                {
                    detail : {
                        product: {
                            ...this.product,
                            quantity,
                        },
                     },
                }
            )
            document.body.dispatchEvent(event)
        })
    }

    onDetailsClick(link) {
        link.addEventListener(`click`, e => {
            e.preventDefault()
            const event = new CustomEvent(
                'GTM:DetailsClick',
                {
                    detail : {
                        product: {
                            ...this.product,
                        },
                     },
                }
            )
            document.body.dispatchEvent(event)
        })
    }

    formatAttribute(name) {
        let attribute = getAttribute(this.element, name)
        if (attribute && name === 'price') {
            attribute = attribute.replace(',', '.')
        }
        if (attribute && name === 'position') {
            attribute = parseInt(attribute)
        }
        if (attribute && attribute === 'None') {
            attribute = null
        }
        return attribute
    }

    addClickEvent(link) {
        new ClickEvent(link, {
            category: 'UX',
            action: 'click',
            label: this.product.list,
        })
    }

    init() {
        if (this.addToCart) {
            this.onAddToCart()
        }
        this.detailsLinks.forEach(link => {
            this.addClickEvent(link)
            this.onDetailsClick(link)
        })
    }
}
