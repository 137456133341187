import Alert from './Alert'

export default class AddToCart {
    constructor(form) {
        this.form = form
        this.code = form.querySelector(`.js-RegisterForm__Code`)
        this.title = form.querySelector(`.js-RegisterForm__Title`)
        this.initialTitle = this.title.innerText
        this.cgv = this.form.querySelector(`.js-RegisterForm__CGV`)
        // this.privacy = this.form.querySelector(`.js-RegisterForm__Privacy`)
        this.siren = this.form.querySelector(`.js-RegisterForm__Siren`)
        this.mandatories = [...this.form.querySelectorAll(`.js-RegisterForm__CustomerMandatory`)]
        this.mandatoryInputs = [...this.form.querySelectorAll(`.js-RegisterForm__CustomerMandatoryInput`)]
        this.isCustomer = false
        this.init()
    }

    toggleRequired(input, required) {
        if (required) {
            input.setAttribute(`required`, `required`)
        } else {
            input.removeAttribute(`required`)
        }
    }

    toggleMandatories(active) {
        this.mandatories.forEach(mandatory => {
            const display = active ? `block` : `none`
            const inputs = [...mandatory.querySelectorAll(`input`)]
            mandatory.style.display = display

            if (active) {
                this.siren.classList.add(`form-group--required`)
            } else {
                this.siren.classList.remove(`form-group--required`)
            }

            inputs.forEach(input => {
                this.toggleRequired(input, active)
            })
        })
        this.mandatoryInputs.forEach(mandatory => {
            this.toggleRequired(mandatory, active)
        })
    }

    notChecked(what, e) {
        e.preventDefault()

        if (what === `cgv`) {
            new Alert(`Conditions Générales de Vente`, `Veuillez s'il vous plait accepter les conditions générales de vente avant de poursuivre.`)
        } else {
            new Alert(`Politique de confidentialité`, `Veuillez s'il vous plait reconnaitre avoir pris connaissance de la politique de confidentialité.`)
        }
    }

    changeTitle(isCustomer) {
        let title = this.initialTitle
        if (isCustomer) {
            title = `Créer mon accès web`
        }
        this.title.innerText = title
    }

    onSubmit(e) {
        if (!this.cgv.checked) this.notChecked(`cgv`, e)
        // if (!this.privacy.checked) this.notChecked(`privacy`, e)
    }


    setForm() {
        this.isCustomer = this.code.value.length > 0

        if (this.isCustomer) {
            this.toggleMandatories(true)
            this.changeTitle(true)
        } else {
            this.toggleMandatories(false)
            this.changeTitle(false)
        }
    }

    init() {
        this.setForm()
        this.form.addEventListener(`submit`, e => this.onSubmit(e))
        this.check
        this.code.addEventListener(`input`, () => this.setForm())
        this.code.addEventListener(`keypress`, () => this.setForm())
    }
}
