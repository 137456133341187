import axios from 'axios'
import Cookies from 'js-cookie'
import Session from './Session'
import Alert from './Alert'
import Modal from 'modal-vanilla/dist/modal'
import ProductPreviews from './ProductPreviews'

export default class AddToCart {
    constructor(form, modal=true, event=false) {
        this.form = form
        this.productRef = this.form.querySelector(`.product-ref`)
        this.productRefTodd = this.form.querySelector(`input.product-ref-todd`)
        this.modal = modal
        this.event = event
        this.button = this.form.querySelector(`button[type="submit"]`)
        this.icon = this.button.querySelector(`.js-AddToCart__Icon`)
        this.iconClass = this.icon.getAttribute('data-icon')
        this.isProductPage = form.classList.contains('js-AddToCartMain')
        this.quantityInput = this.form.querySelector('.qtt-input')
        this.formData = null

        this.successModal = this.getModalElements(`#modal-post-add-to-cart`)
        this.noStockModal = this.getModalElements(`#modal-dispo`)
        this.stocksModal = this.getModalElements(`#modal-dispo-agence`)

        this.data = null
        
        this.matomo = window.matomos.findMatomo(this.productRefTodd.value)
        
        this.init()
    }

    getModalElements(selector) {
        const wrapper = document.querySelector(selector)
        const modal = new Modal({
            el: wrapper,
        })
        window.modalInstances.push(modal)
        return {
            title : wrapper.querySelector(`.modal-title`),
            picture : wrapper.querySelector(`.modal-product-picture`),
            ref : wrapper.querySelector(`input[name='product_ref']`),
            email : wrapper.querySelector(`input[name='email']`),
            stores : wrapper.querySelector(`.stores-list`),
            alsoBought : wrapper.querySelector(`.js-ProductPreviews_AddToCart`),
            instance : modal,
        }
    }

    openSuccessModal() {
        this.successModal.picture.setAttribute(`src`, this.data.pic)
        this.successModal.title.innerText = this.data.title
        this.successModal.instance.show()
    }

    setAlsoBought() {
        this.successModal.alsoBought.innerHTML = `<div class="w-100 d-flex align-items-center justify-content-center">
                        <i aria-hidden="true" data-fa-transform="" class="fal fa-spinner  fa-pulse  fa-2x" align="center"></i>
                    </div>`
        this.successModal.alsoBought.setAttribute('data-product', this.formData.get('product_ref'))
        new ProductPreviews(this.successModal.alsoBought)
    }

    onSuccess(response) {
        let productRefValue = this.productRef.value
        let quantityInputValue = "1"
        if (this.quantityInput != null)
            quantityInputValue = this.quantityInput.value
        
        this.data = response.data
        Object.entries(response.data).forEach(([key, value]) => {
            Session.setItem(key, value)
        })
        
        if (this.matomo == null)
            this.matomo = window.matomos.findMatomo(this.productRefTodd.value)
        if (this.matomo != null) {
            this.matomo.addToCart(
                parseInt(quantityInputValue.replace(",", "."))
            )
        }
        
        if (this.modal) {
            this.openSuccessModal()
        }
        if (this.event) {
            this.sendEvent()
        }
    }

    sendEvent() {
        var event = new CustomEvent(
            'Cart:Add',
            {
                'detail': {
                    title : this.data.title,
                },
            }
        )
        document.dispatchEvent(event)
    }

    noStock(data) {
        this.noStockModal.ref.value = data.no_stock
        this.noStockModal.email.value = data.email
        this.noStockModal.instance.show()
    }

    stocks(data) {
        this.stocksModal.ref.value = data.product_ref
        this.stocksModal.email.value = data.email
        this.stocksModal.instance.show()
    }

    loadButton() {
        this.button.setAttribute('disabled', true)
        this.icon.classList.remove(this.iconClass)
        this.icon.classList.add('fa-spinner', 'fa-pulse')
    }

    unloadButton() {
        this.button.removeAttribute('disabled')
        this.icon.classList.add(this.iconClass)
        this.icon.classList.remove('fa-spinner', 'fa-pulse')
    }

    onError(response) {
        if (response.data.no_stock) {
            this.noStock(response.data)
        }
        if (response.data.stocks) {
            this.stocks(response.data)
        }
        if (response.data.alert) {
            new Alert('Désolé...', response.data.alert)
        }
        if (response.data.ref_todd){
            this.matomo.removeFromCart()
        }
    }

    async submit(e) {
        e.preventDefault()
        this.loadButton()
        if (e.type === 'submit') {
            e.preventDefault()
        }
        if (e.type === 'AMP:AddToCart') {
            this.quantityInput.value = parseInt(e.detail.quantity)
        }

        this.formData = new FormData(this.form)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))


        this.setAlsoBought()

        try {
            const response = await axios.post(`/mon-panier/ajout/`, this.formData)
            this.onSuccess(response)
        } catch(error) {
            this.onError(error.response)
        }
        this.unloadButton()
    }

    init() {
        this.form.addEventListener(`submit`, e => this.submit(e))
        if (this.isProductPage) {
            document.body.addEventListener(`AMP:AddToCart`, e => this.submit(e))
        }
    }
}
