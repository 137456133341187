class WindowOrientation {
    constructor(element) {
        this.body = document.querySelector('body')

        if ('orientation' in window) {
            this.init()
        }
    }

    getOrientation() {
        switch (window.orientation) {
            case 0:
                return 'portrait'
            case 180:
                return 'portrait-reverse'
            case 90:
                return 'landscape'
            case -90:
                return 'landscape-reverse'
        }
    }

    setAttribute() {
        this.body.setAttribute('orientation', this.getOrientation())
    }

    init() {
        window.addEventListener('orientationchange', e => {
            this.setAttribute()
        })
        this.setAttribute()
    }
}

export { WindowOrientation }
