export default class Expand {
    constructor(trigger) {
        this.trigger = trigger
        this.parentSelector = trigger.getAttribute(`data-parent`)
        this.parent = document.querySelector(this.parentSelector)
        this.targets = [...this.parent.querySelectorAll(`.js-Expand__Toggle`)]

        this.init()
    }

    onClick(e) {
        e.preventDefault()
        this.trigger.classList.add(`d-none`)
        this.targets.forEach(target => {
            target.classList.add(`d-block`)
            target.classList.remove(`d-none`)
        })
    }

    init() {
        this.trigger.addEventListener(`click`, e => this.onClick(e))
    }
}
