import Modal from 'modal-vanilla/dist/modal'
import Cookies from 'js-cookie'
import axios from 'axios'
import Alert from './Alert'
export default class AskReplacementModal {
    constructor() {
        this.instance = null
        this.init()
        this.form = document.querySelector('.js-AskReplacementForm')
        this.trigger = document.querySelector('.js-AskReplacementTrigger')
        this.formUrl = null
    }

    onSuccess() {
        this.instance.hide()
    }

    onError(err) {
        new Alert(err)
    }

    onClick(e) {
        this.instance = new Modal({
            el: document.querySelector('#modal-ask-replacement-ref'),
        })
        window.modalInstances.push(this.instance)
        this.instance.show()
    }

    async sendMessage(e) {
        e.preventDefault()
        this.formData = new FormData(this.form)
        this.formData.append(`csrfmiddlewaretoken`, Cookies.get(`csrftoken`))

        try {
            const response = await axios.post(this.formUrl, this.formData)
            this.onSuccess()
        } catch(error) {
            this.onError(error)
        }
    }

    init() {
        window.onload = () => {
            if (this.trigger) {
                this.trigger.addEventListener('click', e => {
                    this.onClick(e)
                })
                this.formUrl = this.form.getAttribute('action')
                this.form.addEventListener('submit', (e) => {
                    this.sendMessage(e)
                })
            }
        }
    }
}
